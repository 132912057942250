$ = jQuery;

var $log = function(msg) {
        console.log(msg);
    },
    App = {
        init: function() {

            this.scripts();

        },
        scripts: function() {

            $("a[href^='" + base_path + "']").each(function(i, o) {

                var $self = $(this),
                    href = $self.attr("href"),
                    href_pure = href.replace(base_path, "");

                if (href_pure.indexOf(".") === -1) {

                    $self.on("click", function(event) {

                        event.preventDefault();
                        var loader_target = $(".loader");

                        loader_target.one("transitionend", function() {

                            loader_target.removeClass("hidden").addClass("loading");

                            $.ajax({
                                type: "GET",
                                url: href,
                                cache: true,
                                error: function(xhr, ajaxOptions, thrownError) {
                                    window.location.href = href;
                                },
                                success: function(json) {
                                    window.location.href = href;
                                }
                            });

                        }).removeClass("loaded");

                    });
                }

            });

            function is_touch_device() {
                return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
            }

            if (is_touch_device()) {
                $('body').addClass('touch');
            } else $('body').addClass('no-touch');

            //setTimeout(function() {

            //}, 1000);
            if ($('.intro-bg').length && $('.intro-bg').css('background-image') != 'none') {
                BackgroundCheck.init({
                    targets: '.page-header',
                    images: '.intro-bg'
                });
            }

            setTimeout(function() {

                $('body').addClass('images-loaded').removeClass('noscroll');

            }, 1400);

            // To top
            $(".back-top").on("click", function() {
                $('html,body').animate({
                    scrollTop: 0
                }, 550);
            });

            $('.loader').on("hide", function() {

                $(this).addClass('loaded hidden');

            });

            // Projects isotope
            var projects_list = $('.projects-list'),
                projects_list_max_pages = parseInt(projects_list.data("max-pages")),
                projects_list_current_page = parseInt(projects_list.data("current-page")),
                projects_wait = false;

            if (projects_list.length) {

                projects_wait = true;
                projects_list.on("layoutComplete", function() {

                    // $log("valmis");
                    $('.loader').trigger("hide");

                });

                projects_list.isotope({
                    itemSelector: '.project-item',
                    percentPosition: false,
                    transitionDuration: '0.65s',
                    //transitionDuration: "0ms",
                    resize: true,
                    hiddenStyle: {
                        opacity: 0
                    },
                    visibleStyle: {
                        opacity: 1,
                    },
                    layoutMode: 'masonry',
                    percentPosition: true,
                    masonry: {
                        columnWidth: '.project-item'
                    },
                    animationOptions: {
                        duration: 0,
                        queue: true
                    }
                });


                $(".load-more-btn a").on("click", function() {

                    var self = $(this);

                    if (projects_list_current_page < projects_list_max_pages && !self.hasClass("disabled")) {

                        projects_list_current_page++;

                        $.get(window.location.href, {
                            paged: projects_list_current_page,
                            time: new Date().getTime()
                        }, function(next_page) {

                            var items = $(next_page).find(".project-item-data");
                            projects_list.isotope().append(items).isotope("appended", items).isotope("layout");

                            if (projects_list_max_pages == projects_list_current_page) {

                                self.addClass("disabled");

                            }

                            var contentH = $(".page-container").outerHeight();

                            //$(".loader").height(contentH);

                        });

                    }
                });

            }

            if (!projects_wait) {
                $('.loader').trigger("hide");
            }

            // Scrolling
            var $win = $(window),
                $body = $("body"),
                $winW = $win.width(),
                //prFilter = $(".projects-filter"),
                introBlock = $(".intro-section"),
                prMeta = $(".project-intro .content-wrapper"),
                prFilterH = 0,
                introH = 0,
                prMetaH = 0,
                footerH = $(".page-footer").outerHeight(),
                scrollPos = $win.scrollTop(),
                loaderBar = $(".loader"),
                backTopBtn = $(".back-top"),
                opacityPercent = scrollPos / introH,
                //loaderBottom = (scrollPos - ($body.outerHeight() - footerH)) / footerH,
                contentH = $(".page-container"),
                setContentH = function() {

                    //loaderBar.height(contentH.outerHeight() + introH);

                };

            if (introBlock.length) {
                introH = introBlock.outerHeight()
            }

            if ($('.detailed-project').length) {
                prMetaH = prMeta.outerHeight();
            }

            if (!$('body').hasClass('page-template-template-contact') && $winW > 1440) {
                $('body').css({
                    'padding-bottom': footerH,
                    'padding-top': introH
                });
            } else if (!$('body').hasClass('page-template-template-contact') && $winW > 767) {
                $('body').css({
                    'padding-bottom': footerH,
                    'padding-top': introH
                });
            } else $('body').css({
                'padding-bottom': 0,
                'padding-top': 0
            });

            if ($(".process-section .process-slider .item").length > 1) {
                $(".process-section .process-slider").each(function() {

                    var $slider = $(this);

                    $(this).data("slider", $(this).on('init reInit', function(event, slick) {
                        setContentH();
                    }).slick({
                        infinite: false,
                        speed: 700,
                        slidesToShow: 1,
                        arrows: true,
                        dots: false,
                        fade: true,
                        centerMode: true,
                        centerPadding: '0px',
                        variableWidth: false
                    }));

                });

            }

            if ($(".intro-bg-slider .bg-slider .item").length > 1) {
                $(".intro-bg-slider .bg-slider").each(function() {

                    var $slider = $(this).on('init reInit', function(event, slick) {
                        setContentH();
                        BackgroundCheck.init({
                            targets: '.page-header',
                            images: '.intro-bg-slider .item-bg'
                        });
                    }).slick({
                        infinite: false,
                        speed: 700,
                        slidesToShow: 1,
                        arrows: false,
                        dots: true,
                        fade: false,
                        centerMode: true,
                        centerPadding: '0px',
                        variableWidth: false
                    }).on('afterChange', function(event, slick) {
                        BackgroundCheck.refresh();
                    });

                });

            } else {
                if ($('.intro-bg-slider').length) {
                    BackgroundCheck.init({
                        targets: '.page-header',
                        images: '.intro-bg-slider .item-bg'
                    });
                }
            }

            setContentH();

            //$log('0. ' + loaderBar.height() + ' introH: ' + introH + ' contentH: ' + contentH + ' $body.outerHeight(): ' + $body.outerHeight());

            $win.on('resize', function() {
                var $winW = $win.width(),
                    footerH = $(".page-footer").outerHeight(),
                    introH = 0,
                    prMetaH = 0;

                if (introBlock.length) {
                    introH = introBlock.outerHeight();
                }

                if ($('.detailed-project').length && $winW > 767) {
                    prMetaH = prMeta.outerHeight();
                    //prFilterH = prFilter.height()
                    //prFilter.css({
                    //	'margin-top': scrollPos
                    //});
                }

                //$log('2. ' + loaderBar.height() + ' introH: ' + introH + ' contentH: ' + contentH);

                if (!$('body').hasClass('page-template-template-contact') && $winW > 1440) {
                    $('body').css({
                        'padding-bottom': footerH,
                        'padding-top': introH
                    });
                } else if (!$('body').hasClass('page-template-template-contact') && $winW > 767) {
                    $('body').css({
                        'padding-bottom': footerH,
                        'padding-top': introH
                    });
                } else $('body').css({
                    'padding-bottom': 0,
                    'padding-top': 0
                });

                var contentH = $(".page-container").outerHeight();

                //loaderBar.height(contentH + introH);

            });

            if (scrollPos > 250) {
                $("body").stop(true, true).addClass('scrolled');
            } else {
                $("body").stop(true, true).removeClass('scrolled');
            }

            if (!$('body').hasClass('page-template-template-contact') && $winW < 767) {

                $(window).on('scroll', function(event) {

                    scrollPos = $win.scrollTop();
                    opacityPercent = scrollPos / introH;

                    if (scrollPos > ($body.outerHeight() - $win.height() - footerH)) {
                        backTopBtn.css({
                            'bottom': scrollPos - ($body.outerHeight() - $win.height() - footerH)
                        });
                    } else {
                        backTopBtn.css({
                            'bottom': 0
                        });
                    }

                });
            }

            if (!$('body').hasClass('page-template-template-contact') && $winW > 767) {

                $(window).on('scroll', function(event) {

                    scrollPos = $win.scrollTop();
                    opacityPercent = scrollPos / introBlock.outerHeight();

                    introBlock.css({
                        'top': scrollPos * -0.1
                    });
                    prMeta.css({
                        'bottom': scrollPos * 0.9,
                        //'transform': 'translate(0,'+ (scrollPos * 0.9) +')'
                    });

                    if (scrollPos > (introBlock.outerHeight() - 1.8 * prMetaH)) {

                        $('.page-header').addClass('nav-down');
                        $('.page-footer').css({
                            'z-index': 2
                        });

                    } else {

                        $('.page-header').removeClass('nav-down');
                        $('.page-footer').css({
                            'z-index': -1
                        });

                    }

                    // $log( scrollPos + ' : ' + ($body.outerHeight()-$win.height()-footerH) + ' = ' + $body.outerHeight() + ':' + introH + ':' + $win.height() + ':' + footerH );

                    if (scrollPos > ($body.outerHeight() - $win.height() - footerH)) {
                        backTopBtn.css({
                            'bottom': scrollPos - ($body.outerHeight() - $win.height() - footerH)
                        });
                    } else {
                        backTopBtn.css({
                            'bottom': 0
                        });
                    }

                });
            }

            if ($winW > 767) {
                $(window).on('scroll', function(event) {

                    scrollPos = $win.scrollTop();

                    if (scrollPos > 250) {
                        $("body").stop(true, true).addClass('scrolled');
                    } else {
                        $("body").stop(true, true).removeClass('scrolled');
                    }
                });
            }

            // Detailed project blocks isotope
            if ($('.detailed-projects').length) {

                $('.images-block').isotope({
                    itemSelector: '.project-img',
                    percentPosition: false,
                    transitionDuration: '0.65s',
                    animationOptions: {
                        duration: 300,
                        queue: true
                    },
                    masonry: {
                        gutterWidth: 0,
                        columnWidth: 0
                    },
                    hiddenStyle: {
                        opacity: 0
                    },
                    visibleStyle: {
                        opacity: 1
                    }
                });

            }

            /*var $projectsList = $('.projects .projects-list'),
            	filters = {};

            $('.projects .projects-list').imagesLoaded(function() {

            	$projectsList.isotope({
            		layoutMode: 'masonry',
            		itemSelector: '.project-item',
            		percentPosition: true,
            		masonry: {
            			columnWidth: '.project-item'
            		},
            		animationOptions: {
            			duration: 300,
            			queue: true
            		}
            	});

            });

            var category_elements = $(".projects-filter .categories a")
            filter = "";

            category_elements.on("click", function(event) {

            	event.preventDefault();

            	$('.projects-list .project-item').removeClass("item-visible");

            	history.pushState({
            		clicked_category: $(this).data("filter")
            	}, document.title, "#" + $(this).data("filter"));

            	$(window).trigger("popstate");

            });

            $(window).on("popstate", function(event) {

            	if (window.location.hash && window.location.hash.length > 1) {

            		var current_element = category_elements.filter("." + window.location.hash.substr(1));

            	}

            	if (!current_element && history.state && history.state.clicked_category) {

            		var current_element = category_elements.filter("." + history.state.clicked_category);

            	}

            	if (current_element) {

            		category_elements.filter(".selected").not(current_element).removeClass("selected");
            		current_element.toggleClass("selected");

            		var new_hash = category_elements.filter(".selected").eq(0).data("filter");

            		if (new_hash && new_hash.length > 1) {

            			history.replaceState({
            				clicked_category: new_hash
            			}, document.title, "#" + new_hash);

            		}
            		else {

            			history.pushState({}, document.title, location.href.substr(0, location.href.length - location.hash.length));

            		}

            		$projectsList.isotope({
            			filter: (window.location.hash && window.location.hash.length > 1 ? "." + window.location.hash.substr(1) : "")
            		});

            	}
            	else {

            		category_elements.filter(".selected").not(current_element).removeClass("selected");

            		$projectsList.isotope({
            			filter: ""
            		});

            	}

            });*/

            if (window.location.hash.length > 1) {

                $(window).trigger("popstate");

            }

        },

        mobMenu: function(menuWrapper) {

            $this = menuWrapper;

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("opened");
                $('body').toggleClass("opened");

            });

            $(window).on("keyup", function(event) {

                if ($('body').hasClass("opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("opened");
                            $('body').removeClass("opened");
                            break;
                    }
                }

            });

        }

    };

$(window).on("load", function() {

    App.init();
    App.mobMenu($('.page-header'));

});